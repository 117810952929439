import React from 'react'
import * as UI from '@/components/UI'
import { Section, Title, Highlight, Grid, Cell } from './CurrentEvents.css'

const CurrentEvents = ({ events, content }) => {
  const [first, second, ...rest] = events
  return (
    <Section>
      <UI.Container>
        <Title>{content.events_title}</Title>
        {first && (
          <Highlight>
            <UI.EventCard
              title={first.meta_title}
              to={`/${first.slug}`}
              date={first.formattedDate}
              toDate={first.to_date}
              kind={first.kind}
              location={first.location}
              image={first.hero_image}
              imagePosition='right'
              fullWidth
            />
          </Highlight>
        )}
        {second && (
          <Highlight>
            <UI.EventCard
              title={second.meta_title}
              to={`/${second.slug}`}
              date={second.formattedDate}
              toDate={second.formattedToDate}
              kind={second.kind}
              location={second.location}
              image={second.hero_image}
              imagePosition='left'
              fullWidth
            />
          </Highlight>
        )}
        {!!rest.length && (
          <Grid>
            {rest?.map(event => (
              <Cell key={event.slug}>
                <UI.EventCard
                  title={event.meta_title}
                  to={`${event.slug}`}
                  date={event.formattedDate}
                  toDate={event.formattedToDate}
                  kind={event.kind}
                  location={event.location}
                  image={event.hero_image}
                />
              </Cell>
            ))}
          </Grid>
        )}
      </UI.Container>
    </Section>
  )
}

export default CurrentEvents
