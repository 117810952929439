import React from 'react'
import * as UI from '@/components/UI'
import { Section, Title, Grid, Cell } from './PastEvents.css'

const PastEvents = ({ events, content }) => {
  const limited = events.slice(0, 6)
  return (
    <Section>
      <UI.Container>
        <Title>{content.past_events_title}</Title>
        {!!limited.length && (
          <Grid>
            {limited?.map(event => (
              <Cell key={event.slug}>
                <UI.EventCard
                  title={event.meta_title}
                  to={`/${event.slug}`}
                  date={event.formattedDate}
                  toDate={event.to_date}
                  kind={event.kind}
                  location={event.location}
                  image={event.hero_image}
                  color='white'
                  background='lightMaastricht'
                />
              </Cell>
            ))}
          </Grid>
        )}
      </UI.Container>
    </Section>
  )
}

export default PastEvents
