import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  background: ${p => p.theme.colors.maastricht};
  color: white;
  padding: 2.5rem 0 5rem;
  ${above('sm')`
    padding: 4rem 0 6rem;
  `}
  ${above('md')`
    padding: 5rem 0 8rem;
  `}
  ${above('lg')`
    padding: 7.5rem 0 10rem;
  `}
`

export const Title = styled.section`
  display: none;
  ${above('md')`
    font-family: ${p => p.theme.type.fonts.headline};
    display: block;
    ${getTypeStyle('mainTitle')};
    margin-bottom: 5rem;
  `}
`

export const Grid = styled(UI.Grid).attrs({
  as: 'ul',
  'vertical-gutter': true,
})``

export const Cell = styled.li`
  width: ${getColumnSize(12)};
  flex: 1;
  align-self: stretch;
  ${above('sm')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
`
