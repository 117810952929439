import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  margin: 1rem 0 2.5rem;
  ${above('sm')`
    margin: 2rem 0 6rem;
  `}
  ${above('md')`
    margin: 4rem 0 8rem;
  `}
  ${above('lg')`
    margin: 6rem 0 10rem;
  `}
`

export const Title = styled.section`
  font-family: ${p => p.theme.type.fonts.headline};
  ${getTypeStyle('title')};
  margin-bottom: 6.5rem;
  ${above('md')`
    ${getTypeStyle('mainTitle')};
    margin-bottom: 5rem;
  `}
`

export const Highlight = styled.div`
  ${above('xs')`
    margin: 1rem 0;
  `}
  ${above('sm')`
    margin: 1.5rem 0;
  `}
  ${above('md')`
    margin: 3.5rem 0;
  `}
`
export const Second = styled.div``

export const Grid = styled(UI.Grid).attrs({
  as: 'ul',
  'vertical-gutter': true,
})``

export const Cell = styled.li`
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
`
