import React from 'react'
import { graphql } from 'gatsby'
import dayjs from 'dayjs'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import Contact from '@/components/contents/shared/Contact/Contact'
import CurrentEvents from '@/components/contents/EventsIndex/CurrentEvents'
import PastEvents from '@/components/contents/EventsIndex/PastEvents'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'
import { ContactWrapper } from '@/components/contents/ContactIndex/Contact.css'
import srcHeart from '@/assets/images/nobox-red.png'
import srcYellow from '@/assets/images/nobox-yellow.png'
import srcCloud from '@/assets/images/nobox-blue.png'
import * as UI from '@/components/UI'
import getMetaImage from '@/utils/getMetaImage'

const EventsPage = ({ data }) => {
  const content = { ...data?.content?.data, slug: data?.content?.slug }
  const events = data?.events?.edges?.map(({ node }) => ({
    ...node.data,
    slug: `${content.slug}/${node.slug}`,
  }))
  const today = dayjs(Date()).format('YYYY-MM-DDTHH:mm:ss+SSS')
  const currentEvents = events?.filter(x => x.date > today)
  const pastEvents = events?.filter(x => x.date < today)

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      {!!currentEvents.length && (
        <CurrentEvents content={content} events={currentEvents} />
      )}
      {!!pastEvents.length && (
        <PastEvents content={content} events={pastEvents} />
      )}
      <ContactWrapper isEvents>
        <UI.Parallax top='-5%' right='30%' force={1.1} offsetComp={20}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0) rotate(-135deg)`,
              }}
              src={srcYellow}
              alt={content.meta_title}
              width='360'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <UI.Parallax top='30%' left='-140px' force={1.2} offsetComp={20}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcCloud}
              alt={content.meta_title}
              width='340'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <UI.Parallax bottom='20%' right='250px' force={1.2} offsetComp={20}>
          {offSet => (
            <UI.ImageLoose
              style={{
                transform: `translateY(${offSet}px) translateZ(0)`,
              }}
              src={srcHeart}
              alt={content.meta_title}
              width='340'
              hiddenMobile
            />
          )}
        </UI.Parallax>
        <Contact content={content} />
      </ContactWrapper>
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query EventsPage($locale: String) {
    events: allPrismicEvent(
      filter: { lang: { eq: $locale } }
      sort: { order: DESC, fields: data___date }
    ) {
      edges {
        node {
          slug: uid
          lang
          data {
            date
            formattedDate: date(formatString: "DD.MM")
            to_date
            meta_title
            kind
            location
            meta_image {
              localFile {
                childImageSharp {
                  fixed(width: 1200, height: 630) {
                    src
                  }
                }
              }
            }
            hero_image {
              alt
              localFile {
                childImageSharp {
                  fluid {
                    aspectRatio
                    originalName
                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
    content: prismicEventsPage(lang: { eq: $locale }) {
      lang
      slug: uid
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        events_title
        past_events_title
        see_all
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EventsPage
